/* global _, Backbone, $, _E */
import { noty } from 'helpers/flash'
import { deepFromFlat } from 'helpers/deep'
import Relatable from 'models/modules/relatable'

export default class Base extends Backbone.NestedModel {
  modelPrefix () {
    return null
  }

  parentName () {
    return 'parent'
  }

  identifier () {
    return this.id
  }

  isOwned () {
    return this.get('user_id') === _E.currentUser.id
  }

  name () {
    const names = []

    if (!this.get('owned') && this.user) {
      if (this.userUrl) {
        names.push(
          `<a class='blue' href='${this.userUrl()}'>${this.user.get(
            'full_name'
          )}</a>`
        )
      } else {
        names.push(this.user.get('full_name'))
      }
    }
    names.push(this.get('name'))

    return names.join(' &mdash; ')
  }

  uid () {
    return this.cid
  }

  parse (attrs) {
    _.extend(this, Relatable)
    const deep = this.get('deep')
    const shallow = this.get('shallow')
    attrs = super.parse(attrs)
    this.readonly = new Backbone.NestedModel(attrs.readonly, { parse: true })

    // protect against parsing after an update on a deep model which retrieves shallow
    // decorated attributes
    if (deep) {
      this.readonly.attributes.deep = true
    }
    if (shallow != null && !shallow) {
      this.readonly.attributes.shallow = false
    }
    delete attrs.readonly
    return attrs
  }

  // use when you don't want to re-parse the entire model; does not parse relationships
  parseAttributes (attrs) {
    this.readonly.set(attrs.readonly)
    delete attrs.readonly
    this.set(attrs)
  }

  toJSON (options) {
    if (this.id && options?.autosave) {
      // only return the changed attributes; goes with patch: true in 'save'
      return _.clone(this.shallowChangedAttributes() || this.attributes)
    } else {
      return _.clone(this.attributes)
    }
  }

  shallowChangedAttributes () {
    const changed = this.changedAttributes()
    const actuallyChanged = {}

    // when nested, ignore whole object changes if Backbone object and field changes otherwise
    _(changed).each((value, key) => {
      if (!_.isObject(value)) {
        const keys = key.split('.')
        if (keys.length === 2) {
          if (this.get(keys[0]).id) {
            actuallyChanged[key] = value
          }
        } else {
          actuallyChanged[key] = value
        }
      } else if (typeof value === 'object' && this.get(key).id == null) {
        actuallyChanged[key] = value
      }
    })

    return deepFromFlat(actuallyChanged)
  }

  get (attr) {
    const value = super.get(attr)
    if (value != null || this.readonly == null) {
      return value
    } else {
      return this.readonly.get(attr)
    }
  }

  set (key, val, options) {
    const opts = typeof key === 'object' ? val : options
    if (opts?.autosave) {
      // only set readonly attributes, which are already parsed
      return this
    } else {
      return super.set(key, val, opts)
    }
  }

  // cache a temporary, in-memory value
  cache (key) {
    let value
    if ((value = this.readonly.get(key)) == null) {
      value = this[key]()
      this.readonly.set(key, value, { silent: true })
    }
    return value
  }

  clearCache (key) {
    return this.readonly.set(key, null, { silent: true })
  }

  // whether the model has permission to perform a specific ability
  can (ability) {
    const abilities = this.get('abilities')
    if (abilities) {
      return _.contains(abilities, ability)
    } else {
      return false
    }
  }

  // used when an object is used immediately after being created locally
  allow (ability) {
    const abilities = this.get('abilities')
    if (abilities) {
      abilities.push(ability)
    } else {
      this.set('abilities', [ability])
    }
  }

  permissiveUrl (suffix = '', authModel) {
    if (authModel == null) {
      authModel = this
    }
    if (authModel.can('update')) {
      return this.url() + '/edit' + suffix
    } else {
      return this.url()
    }
  }

  save (key, val, options) {
    let newKey, newOptions
    const prefix = this.modelPrefix()

    if (key == null || typeof key === 'object') {
      if (!val) {
        val = {}
      }
      newOptions = val
      if (prefix && typeof key === 'object') {
        newKey = {}
        newKey[prefix] = key
        key = newKey
      }
    } else {
      if (!options) {
        options = {}
      }
      newOptions = options
      if (prefix) {
        newKey = {}
        newKey[prefix] = {}
        newKey[prefix][key] = val
        key = newKey
        val = options
        options = null
      }
    }

    const { success } = newOptions
    newOptions.success = (model, resp, options) => {
      this.trigger('save', this, options)
      if (success) {
        success(model, resp, options)
      }
    }
    // newOptions.patch ?= true if @id? and (newOptions.autosave || key?)

    return super.save(key, val, options)
  }

  command (url, options = {}) {
    _.defaults(options, {
      data: {},
      method: 'POST',
      sync: false,
      success: $.noop,
      error: $.noop,
      url,
      contentType: 'application/json',
      complete: false, // for auto-save
      reload: false,
    })

    options.data = options.method == 'GET' ? null : JSON.stringify(options.data)
    const { success } = options
    options.success = (resp) => {
      if (options.sync) {
        options.successBeforeSync?.(this, resp, options)
        this.set(this.parse(resp, options), options)
        this.trigger('sync', this, resp, options)
      }
      success?.(this, resp, options)
      this.trigger('command', this, resp, options)
    }

    const { error } = options
    options.error = (jqXHR, textStatus) => {
      if (jqXHR.status === 419) {
        // authentication timeout
        this.handleAuthenticationTimeout()
      } else {
        // patterned after Backbone wrapError function
        error(this, jqXHR, options)
        this.trigger('error', this, jqXHR, options)
      }
    }

    if (options.reload) {
      this.trigger('reload')
    }

    this.sync(null, this, options)
  }

  handleAuthenticationTimeout () {
    window.blessedDeparture = true

    if (this.errorMsg == null) {
      this.errorMsg = noty({
        layout: 'topCenter',
        text: 'Sorry, your user credentials are missing. Please reload this browser window to continue.',
        type: 'error',
        timeout: 30000,
        callbacks: {
          onClose: () => {
            this.errorMsg = null
          },
        },
      })
    }
  }
}
